.promptButton {
  background: white;
  color: #383645;
  border-radius: 8px;
  border: 1px solid #e9e8ed;
  margin-bottom: 6px;
  &:hover {
    border-color: #bab0fd;
  }
}

.promptButtonSelected {
  background: var(--mantine-color-violet-0);
  color: #383645;
  border-radius: 8px;
  border: 1px solid #bab0fd;
  margin-bottom: 6px;
}

.rowIcon {
  &:hover {
    stroke: #545167;
  }
}

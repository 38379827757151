.input {
  height: 27px;
  border: 1px solid var(--mantine-color-gray-2);
  max-height: 27px;
  min-height: 27px;
}

.wrapper {
  max-height: 27px;
}

.select-input {
  border: 1px solid var(--mantine-color-gray-2);
}

.select-label {
  font-size: 12px;
  color: var(--mantine-color-gray-6);
}

.label {
  font-size: 14px;
  font-weight: 600;
  color: var(--mantine-color-gray-5);
}

.chevron {
  margin-left: 0;
  margin-right: 6px;
}

.content {
  padding: 10px 0 0 0;
}

.control {
  height: 20px;
}
